<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <h2 v-if="categoryName">{{categoryName}}</h2>
      </div>
      <div class="col-12">The following editor adds content to every product belonging to the {{categoryName}} category, below the description.</div>
    </div>
    <div class="row">
      <div class="col-12">
        <page-content-editor modelType="category" :modelId="categoryId" v-if="categoryId" />
      </div>
    </div>
  </div>
</template>

<script>
import PageContentEditor from "./PageContentEditor";
import axios from "./../http.js";

export default {
  components: { PageContentEditor },
  data: function () {
    return {
      categoryId: this.$route.params.id,
      categoryName: null,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      axios.get("/api/categories/" + this.categoryId).then((response) => {
        this.categoryName = response.data.data.name;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
.coupon-image {
  max-width: 1200px;
  width: 100%;
}
</style>